import React from "react"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Nuestros servicios"
      description="Damos tratamiento (limpieza y planchado) a sus prendas: Camisas, Sweteres, Pantalones, Calcetines, Trajes P/Caballero, Corbatas, Vestidos, Lana, Faldas, Blusas, Chalecos, Abrigos, Gabardinas."
    />
    <div className="mc-wrapper-2">
      <div className="mc-wrapper-3 extra">
        <h1 className="title">Nuestros Servicios</h1>
        <p className="first">
          Damos tratamiento – limpieza y planchado – a sus prendas de cualquier
          temporada climatica y en cualquier tamaño, color, o forma. Tan solo
          acuda a nuestras instalaciones en los horarios de servicio con sus
          prendas en mano, y nos encargaremos del resto.
        </p>
        <div className="mc-col-group clearfix">
          <div className="column">
            <h2>Prendas de vestir</h2>
            <p>
              Incluye la mayor parte de prendas de vestir (con algunas
              excepciones), tales como:
            </p>
            <ul className="garment-list">
              <li>Camisas</li>
              <li>Sweteres</li>
              <li>Pantalones</li>
              <li>Calcetines</li>
              <li>Trajes P/Caballero</li>
              <li>Corbatas</li>
              <li>Vestidos</li>
              <li>Lana</li>
              <li>Faldas</li>
              <li>Blusas</li>
              <li>Chalecos</li>
              <li>Abrigos</li>
              <li>Gabardinas</li>
            </ul>
          </div>
          <div className="column">
            <h2>Prendas que no se visten</h2>
            <p>
              Incluyen prendas que si bien no son de vestir, forman parte de
              nuestra vida cotidiana, por ejemplo:
            </p>
            <ul className="garment-list">
              <li>Colchas</li>
              <li>Cobijas</li>
              <li>Almoadas</li>
              <li>Edredones</li>
              <li>Cortinas</li>
              <li>Botargas</li>
              <li>Manteles</li>
              <li>Sleeping bags</li>
            </ul>
          </div>
          <div className="column">
            <h2>Prendas de cuidado especial</h2>
            <p>
              Incluyen prendas que por su naturaleza requieran de cuidados
              especiales, tales como:
            </p>
            <ul className="garment-list">
              <li>Pieles</li>
              <li>Gamuza</li>
              <li>Sedas</li>
              <li>Ropones</li>
              <li>Vestidos de Novia</li>
              <li>Vestidos de XV años</li>
              <li>Vestidos de noche</li>
              <li>1ra. Comunión</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
